import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Index from "./pages/index";
import GeneralLayout from "./theme/general-layout";
import DocsLayout from "./theme/docs-layout";
// import Docs from "./pages/docs";
import Plans from "./pages/plans";
import Contact from "./pages/contact";
import Introduction from "./pages/docs/introduction";
import Install from "./pages/docs/install";
import Register from "./pages/docs/register";
import Config from "./pages/docs/config";
import Features from "./pages/docs/features";
import Invoice from "./pages/docs/invoice";
import Inventory from "./pages/docs/inventory";
import Reports from "./pages/docs/reports";
import Faqs from "./pages/docs/faqs";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<GeneralLayout />}>
          <Route path="/" element={<Index />} />
          <Route path="/inicio" element={<Index />} />
          <Route path="/index" element={<Index />} />
          <Route path="/planes" element={<Plans />} />
          <Route path="/contacto" element={<Contact />} />
        </Route>
        <Route element={<DocsLayout />}>
          <Route path="/introduccion" element={<Introduction />} />
          <Route path="/instalacion" element={<Install />} />
          <Route path="/registro" element={<Register />} />
          <Route path="/configuracion" element={<Config />} />
          <Route path="/modulos" element={<Features />} />
          <Route path="/facturacion" element={<Invoice />} />
          <Route path="/inventario" element={<Inventory />} />
          <Route path="/reportes" element={<Reports />} />
          <Route path="/preguntas_frecuentes" element={<Faqs />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
