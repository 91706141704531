export default function HeaderDocs() {
  return (
    <header className="header fixed-top">
      <div className="branding docs-branding">
        <div className="container-fluid position-relative py-2">
          <div className="docs-logo-wrapper">
            <button
              id="docs-sidebar-toggler"
              className="docs-sidebar-toggler docs-sidebar-visible me-2 d-xl-none"
              type="button"
              
              title="clic para mostrar menu"
              aria-label="clic para mostrar menu"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <div className="site-logo">
              <a className="navbar-brand" href="/inicio">
                <img
                  className="logo-icon me-2"
                  src="assets/images/site-logo.svg"
                  alt="logo"
                  
                />
                <span className="logo-text">
                  Pro<span className="text-alt">Venta</span>
                </span>
              </a>
            </div>
          </div>
          <div className="docs-top-utilities d-flex justify-content-end align-items-center">
            <div className="top-search-box d-none d-lg-flex">
              <form className="search-form" style={{ display: "none"}}>
                <input
                  type="text"
                  placeholder="Search the docs..."
                  name="search"
                  className="form-control search-input"
                />
                <button type="submit" className="btn search-btn" value="Search">
                  <i className="fas fa-search"></i>
                </button>
              </form>
            </div>

            <ul className="social-list list-inline mx-md-3 mx-lg-5 mb-0 d-none d-lg-flex">
              <li className="list-inline-item">
                <a href="/">
                  <i className="fab fa-github fa-fw"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/">
                  <i className="fab fa-twitter fa-fw"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/">
                  <i className="fab fa-slack fa-fw"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/">
                  <i className="fab fa-product-hunt fa-fw"></i>
                </a>
              </li>
            </ul>
            <a href="http://proventa.app" className="btn btn-primary d-none d-lg-flex">
              Descargar
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
