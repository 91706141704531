import SEO from "../../components/seo";

const Introduction = (props) => {
  return (
    <>
    <SEO
      title="ProVenta - documentación online que te servirá a responder tus dudas"
      description="Bienvenido a proventa, aquí encontraras toda la documentación que buscas"
      name="ProVenta"
      type="article" />
      <article className="docs-article" id="section-1">
        <header className="docs-header">
          <h1 className="docs-heading">
            Bienvenida{" "}
            <span className="docs-time">Ultima actualización: 2024-07-30</span>
          </h1>
          <section className="docs-intro">
            <p>
              Bienvenido a <strong>ProVenta</strong>, tu solución integral para la
              gestión eficiente de facturación e inventario. Simplifica y optimiza
              tus operaciones empresariales con nuestras herramientas fáciles de
              usar.
            </p>
          </section>
          <div className="row mt-4">
            <h2 className="docs-heading">Introducción</h2>
            <section className="docs-intro">
              <p>
                <strong>ProVenta</strong>, es un sistema intuitivo de facturación
                que te permite enviar facturas y recibir pagos fácilmente. Hemos
                diseñado nuestro sistema para que sea sencillo, pero completo,
                ofreciéndote todo lo necesario para que te enfoques en el
                crecimiento de tu negocio. Con ProVenta, puedes crear facturas,
                registrar cobros, llevar un control de tus gastos. Nosotros nos
                encargamos de calcular tus ventas, para que tú no tengas que
                preocuparte por los números.
              </p>
            </section>
          </div>
          <h5>Antes de empezar</h5>
          <section className="docs-intro">
            <p>
              Hay algunas cosas que necesita antes de comenzar a usar ProVenta.
              Si estás leyendo esto, probablemente lo tengas todo. De
              todos modos, necesitarías:
              <div className="row mt-4">
                <ol>
                  <li>Una PC con windows</li>
                  <li>Una conexión a Internet estable</li>
                  <li>Una impresora de punto de venta(Opcional)</li>
                  <li>Una impresora standard(Opcional)</li>
                </ol>
              </div>
            </p>
          </section>

          <h5>Proximo paso - La instalación:</h5>
          <p>
            ahora que ya tienes una idea de lo que es ProVenta vamos al segundo al proceso de instalación
            <a
              href="/instalacion"
            >
              {" "}proximo paso {">>>"}
            </a>
          </p>
        </header>
      </article></>
  );
};
export default Introduction;
