import SEO from "../../components/seo";

const Register = (props) => {
  return (
    <>
      <SEO
        title="ProVenta - registro de empresa"
        description="manual de paso a paso de como registrar tu empresa en proventa y empezar a facturar"
        name="ProVenta"
        type="article"
      />
      <article className="docs-article" id="section-1">
        <header className="docs-header">
          <h1 className="docs-heading">Registro de empresa </h1>
          <section className="docs-intro">
            <div className="card mb-4" style={{ width: "50rem" }}>
              <img
                src="/assets/pantalla_inicio.png"
                className="card-img-top"
                alt="pantalla de registro de nueva empresa"
              />
              <div className="card-body">
                <p className="card-text">
                  Clic en el botón "Registrar ahora" para iniciar el proceso
                </p>
              </div>
            </div>
            <p>
              Bienvenido al registro de empresa de <strong>ProVenta</strong>. En
              esta sección, podrás ingresar la información esencial de tu
              empresa para comenzar a utilizar todas las funcionalidades de
              nuestro sistema. Completar el registro te permitirá personalizar
              tus facturas, gestionar tu inventario y llevar un control
              detallado de tus operaciones diarias. ¡Sigue los pasos a
              continuación y empieza a optimizar la gestión de tu negocio!
            </p>
          </section>
          <div className="row mt-4">
            <h2 className="docs-heading">Pantalla de registro</h2>
            <section className="docs-intro">
              <div className="card mb-4" style={{ width: "32rem" }}>
                <img
                  src="/assets/pantalla_registro.png"
                  className="card-img-top"
                  alt="pantalla de registro de nueva empresa"
                />
                <div className="card-body">
                  <p className="card-text">
                    El proceso de registro es super sencillo, solo te pedimos
                    algunos datos básicos
                  </p>
                </div>
              </div>
              <p>
                <strong>
                  Después del registro podrás completar otros datos de
                  información:{" "}
                </strong>
                Una vez que hayas creado tu cuenta, tendrás la oportunidad de
                agregar más detalles sobre la empresa, como la dirección, número
                de teléfono, etc. Esta información adicional suele ser opcional
                y puedes completarla más adelante en la configuración.
              </p>
            </section>
          </div>
          <h5>Datos solicitados</h5>
          <section className="docs-intro">
            <p>
              <div className="row mt-4">
                <ol>
                  <li>
                    <strong>Nombre de la empresa:</strong> Nombre de la empresa.
                  </li>
                  <li>
                    <strong> Nombre de contacto:</strong> Indícanos tu nombre y
                    apellido para poder dirigirnos a ti correctamente.
                  </li>
                  <li>
                    <strong> Correo electrónico:</strong> Una dirección de
                    correo electrónico válida para poder verificar tu cuenta y
                    recibir notificaciones.
                  </li>

                  <li>
                    <strong>Selecciona un plan:</strong> Selecciona un{" "}
                    <a href="/planes">plan</a> que mejor se adapte a tu negocio.
                  </li>
                  <li>
                    <strong>Contraseña:</strong> Una contraseña segura para
                    proteger tu cuenta.
                  </li>
                </ol>
              </div>
            </p>
          </section>

          <h5>
            <a href="/configuracion">Proximo paso - configuración inicial:</a>
          </h5>
          <p>
            Para empezar con la facturación básicamente solo necesitas crear al
            menos un producto, en el proximo paso te explicamos que necesitas
            para empezar a facturar. &nbsp;
            <a href="/configuracion">proximo paso {">>>"}</a>
          </p>
        </header>
      </article>
    </>
  );
};
export default Register;
