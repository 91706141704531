import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer";
import HeaderDocs from "./header-docs";
import DocsSideBar from "./docs-sidebar";
import { HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

const DocsLayout = (props) => (
  <>
  <HelmetProvider context={helmetContext}>
    <HeaderDocs />
    <div className="docs-wrapper">
      <DocsSideBar />
      <div className="docs-content">
        <div className="container">
          <Outlet />
        </div>
      </div>
    </div>
    <Footer />
    </HelmetProvider>
  </>
);
export default DocsLayout;
