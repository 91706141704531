export default function Footer() {
    return (
        <footer className="footer">
	    <div style={{ display: 'none'}} className="container py-5 mb-3">
		    <div className="row">
			    <div className="footer-col col-6 col-lg-3">
				    <h4 className="col-heading">Products</h4>
				    <ul className="list-unstyled">
					    <li><a className="text-link" href="/">Bootstrap Themes</a></li>
					    <li><a className="text-link" href="/">Design Templates</a></li>
					    <li><a className="text-link" href="/">Live Demo</a></li>
					    <li><a className="text-link" href="/">Showcase</a></li>
					    
				    </ul>
			    </div>
			    <div className="footer-col col-6 col-lg-3">
				    <h4 className="col-heading">Developers</h4>
				    <ul className="list-unstyled">
					    <li><a className="text-link" href="/">Pricing</a></li>
					    <li><a className="text-link" href="/">APIs</a></li>
					    <li><a className="text-link" href="/">FAQs</a></li>
					    <li><a className="text-link" href="/">Support</a></li>
				    </ul>
			    </div>
			    <div className="footer-col col-6 col-lg-3">
				    <h4 className="col-heading">Resources</h4>
				    <ul className="list-unstyled">
					    <li><a className="text-link" href="/">Docs</a></li>
					    <li><a className="text-link" href="/">Freebies</a></li>
					    <li><a className="text-link" href="/">Affiliates</a></li>
					    <li><a className="text-link" href="/">Community</a></li>
				    </ul>
			    </div>
			    <div className="footer-col col-6 col-lg-3">
				    <h4 className="col-heading">CoderPro</h4>
				    <ul className="list-unstyled">
					    <li><a className="text-link" href="/">About Us</a></li>
					    <li><a className="text-link" href="/">Contact Us</a></li>
					    <li><a className="text-link" href="/">Privacy</a></li>
					    <li><a className="text-link" href="/">Terms of Services</a></li>
				    </ul>
		            
			    </div>
		    </div>
	    </div>
	    <div style={{ display: "none" }} className="container">
		    <hr />
	    </div>
	    <div className="newsletter-section py-5">
		    <div className="container text-center">
			    <h4 className="mb-2">¿Necesitas más información? ¡Contáctanos ahora!</h4>
			    <div className="section-intro mb-2">Estamos aquí para ti. Haznos cualquier pregunta y despeja tus dudas.</div>
		        <form className="signup-form row gx-1 justify-content-center pt-3">   
                    <div className="col-12 col-md-auto mt-2 mt-md-0">
                      <a href="tel:18097874963" className="btn btn-primary fs-5"><i className="fa-brands fa-whatsapp fa-xl"></i> (809) 787-4963</a>
                    </div>
                </form>
		    </div>
	    </div>
	    <div className="footer-bottom text-center pb-5">
	        <small className="copyright">Copyright &copy; <a className="text-link" href="https://proventa.app" target="_blank" rel="noreferrer">CreativeLabs</a></small>
	        <ul className="social-list list-unstyled pt-4 mb-0">
			    <li className="list-inline-item"><a href="https://github.com/Isidroca"><i className="fab fa-github fa-fw"></i></a></li> 
	            <li className="list-inline-item"><a href="https://do.linkedin.com/company/proventa-pos"><i className="fab fa-linkedin"></i></a></li>
	            <li className="list-inline-item"><a href="https://www.facebook.com/sistemaproventa"><i className="fab fa-facebook-f fa-fw"></i></a></li>
	            <li className="list-inline-item"><a href="https://www.instagram.com/proventa.rd"><i className="fab fa-instagram fa-fw"></i></a></li>
	        </ul>
	    </div>
    </footer>
    );
  }
  