import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer";
import Header from "./header";
import { HelmetProvider } from 'react-helmet-async';

const helmetContext = {};
const GeneralLayout = (props) => (

  <HelmetProvider context={helmetContext}>
    <Header />
    <Outlet />
    <Footer />
  </HelmetProvider>
);

export default GeneralLayout;
