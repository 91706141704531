import SEO from "../../components/seo";

const Install = (props) => {
  return (
    <><SEO
      title="ProVenta - instalación y configuración"
      description="como instalar proventa en windows, te damos un paso a paso para instalar la aplicación"
      name="ProVenta"
      type="article" />
      <article className="docs-article" id="section-1">
        <header className="docs-header">
          <h1 className="docs-heading">Instalación</h1>
          <section className="docs-intro">
            <p>
              Lo primero que debes hacer es descargar la última versión del
              sistema desde el siguiente enlace:
              <a target="_blank" rel="noreferrer" href="https://proventa.app">
                {" "}
                https://proventa.app
              </a>{" "}
              Visita la página y selecciona la versión más reciente compatible con
              tu sistema operativo. Haz clic en el botón de descarga para obtener
              el archivo de instalación.
            </p>
          </section>
          <div className="row mt-4">
            <h2 className="docs-heading">Pasos a seguir</h2>
            <section className="docs-intro">
              <p>
                <div className="alert alert-warning" role="alert">
                  <i className="fas fa-bullhorn"></i><strong> Advertencia de Windows Defender: </strong>Durante la
                  instalación, es posible que Windows Defender muestre un aviso de
                  seguridad. Esto es un procedimiento estándar para archivos
                  descargados de Internet.
                </div>
              </p>
              <div className="row mt-4">
                <ol>
                  <li>
                    Descargar el archivo: Visita la página de descargas y
                    selecciona la versión más reciente compatible con tu sistema
                    operativo. Haz clic en el botón de descarga para obtener el
                    archivo de instalación.
                  </li>
                  <li>
                    <div className="card" style={{ width: "32rem" }}>
                      <img
                        src="/assets/windows_defender_alerta.png"
                        className="card-img-top"
                        alt="..." />
                      <div className="card-body">
                        <p className="card-text">
                          Haz clic en "Más información" en la ventana de
                          advertencia.
                        </p>
                      </div>
                    </div>
                    <div className="card mt-3" style={{ width: "32rem" }}>
                      <img
                        src="/assets/windows_defender_alerta_aceptar.png"
                        className="card-img-top"
                        alt="..." />
                      <div className="card-body">
                        <p className="card-text">
                          Luego, selecciona "Ejecutar de todas formas" para
                          continuar con la instalación.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    En este punto la instalación se hace de manera automática,
                    cuando termine la instalación el sistema se abrirá
                    automáticamente y te creara un acceso rápido en el escritorio
                    de tu PC.
                  </li>
                  <li>
                    <strong>Verificación:</strong> Una vez completada la
                    configuración, verifica que todo esté funcionando
                    correctamente. Si encuentras algún problema, consulta la
                    sección de Solución de Problemas o contacta a nuestro equipo
                    de soporte para asistencia.
                  </li>
                </ol>
              </div>
              <div>
              <strong>Proximo paso - Registro de empresa:</strong>
              <p>
              <a href="/registro">
              {" "}
              registrar tu empresa {">>>"}
            </a>
            </p>
              </div>
            </section>
          </div>
          <hr />
          <section className="docs-section">
            <h2 className="section-heading">Solo para los usuarios del navegador Microsoft Edge</h2>
            <p>
              Si estas usando este navegador sigue estas instrucciones:
              <div className="row mt-4">
                <ol>
                  <li>
                    <div className="card mt-3" style={{ width: "32rem" }}>
                      <img
                        src="/assets/microsoft_edge_confirmar_descarga.png"
                        className="card-img-top"
                        alt="..." />
                      <div className="card-body">
                        <p className="card-text">
                          Selecciona los tres puntitos y luego "Keep" o "Mantener"
                          en español para aceptar el archivo.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card mt-3" style={{ width: "22rem" }}>
                      <img
                        src="/assets/windows_defender_aceptar_archivo.png"
                        className="card-img-top"
                        alt="..." />
                      <div className="card-body">
                        <p className="card-text">
                          Selecciona mostrar mas y luego "Keep anyway" o "Mantener de todas formas"
                          en español para proceder con la instalación.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="card mt-3" style={{ width: "22rem" }}>
                      <img
                        src="/assets/instalacion_proventa.png"
                        className="card-img-top"
                        alt="..." />
                      <div className="card-body">
                        <p className="card-text">
                          Ahora solo tienes que hacer clic en el nombre del archivo para iniciar el proceso de instalación
                        </p>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </p>
          </section>

          <h5>Proximo paso - Registro de empresa:</h5>
          <p>
            ahora que ya tienes una idea de lo que es ProVenta vamos al segundo al
            proceso de instalación
            <a href="/registro">
              {" "}
              registro de empresa {">>>"}
            </a>
          </p>
        </header>
      </article></>
  );
};
export default Install;
