const DocsSideBar = (props) => {
  return (
    <div className="docs-wrapper">
    <div id="docs-sidebar" className="docs-sidebar">
      <div className="top-search-box d-lg-none p-3">
        <form className="search-form">
          <input
            type="text"
            placeholder="Search the docs..."
            name="search"
            className="form-control search-input"
          />
          <button type="submit" className="btn search-btn" value="Search">
            <i className="fas fa-search"></i>
          </button>
        </form>
      </div>
      <nav id="docs-nav" className="docs-nav navbar">
        <ul className="section-items list-unstyled nav flex-column pb-3">
          <li className="nav-item section-title">
            <a className="nav-link scrollto active" href="/introduccion">
              <span className="theme-icon-holder me-2">
                <i className="fas fa-map-signs"></i>
              </span>
              Inicio
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link scrollto" href="/introduccion">
              Bienvenida
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link scrollto" href="/introduccion">
              Introducción a la Aplicación
            </a>
          </li>
          <li className="nav-item section-title mt-3">
            <a className="nav-link scrollto" href="/instalacion">
              <span className="theme-icon-holder me-2">
                <i className="fas fa-arrow-down"></i>
              </span>
              Guía de Inicio Rápido
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link scrollto" href="/instalacion">
            Instalación
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link scrollto" href="/registro">
            Registra tu empresa
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link scrollto" href="/configuracion">
            Configuración Inicial
            </a>
          </li>
          <li className="nav-item section-title mt-3">
            <a className="nav-link scrollto" href="/modulos">
              <span className="theme-icon-holder me-2">
                <i className="fas fa-cogs"></i>
              </span>
              Funciones Principales
            </a>
          </li>
          
          <li className="nav-item">
            <a className="nav-link scrollto" href="/facturacion">
            Facturación
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link scrollto" href="/inventario">
            Gestión de Inventarios
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link scrollto" href="/reportes">
            Reportes y Análisis
            </a>
          </li>
          <li className="nav-item section-title mt-3">
            <a className="nav-link scrollto" href="/contacto">
              <span className="theme-icon-holder me-2">
                <i className="fas fa-tools"></i>
              </span>
              Solución de Problemas
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link scrollto" href="/preguntas_frecuentes">
            Preguntas Frecuentes
            </a>
          </li>
          <li className="nav-item section-title mt-3">
            <a className="nav-link scrollto" href="/contacto">
              <span className="theme-icon-holder me-2">
                <i className="fas fa-laptop-code"></i>
              </span>
              Contacto y Soporte
            </a>
          </li>
        </ul>
      </nav>
    </div>
    </div>
  );
};
export default DocsSideBar;
