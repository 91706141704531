import SEO from "../../components/seo";

const Invoice = (props) => {
  return (
    <>
      <SEO
        title="Sistema de Facturación Eficiente y Automatizado - ProVenta"
        description="Optimiza la gestión de ventas con el módulo de facturación de ProVenta. Emite facturas rápidas, personalizadas y con cumplimiento fiscal, mientras gestionas descuentos, impuestos y métodos de pago. Mejora tu negocio con nuestro sistema eficiente y fácil de usar."
        name="ProVenta"
        type="article"
      />
      <article className="docs-article" id="section-1">
        <header className="docs-header">
          <h1 className="docs-heading">Pantalla Principal de Facturación </h1>
          <section className="docs-intro">
            <div className="card mb-4" style={{ width: "50rem" }}>
              <img
                src="/assets/pantalla_facturacion.png"
                className="card-img-top"
                alt="pantalla de generación de facturas"
              />
              <div className="card-body">
                <p className="card-text">Pantalla principal de facturación</p>
              </div>
            </div>
            <p>
              La pantalla principal de facturación es el corazón del sistema
              <strong> ProVenta</strong>. Aquí es donde se gestionan todas las
              operaciones de facturación de manera eficiente y personalizada. A
              continuación, se describen las secciones clave de esta pantalla:
            </p>
          </section>
          <div className="row mt-4">
            <h4>Barra Superior</h4>
            <section className="docs-intro">
              <div className="row mt-4">
                <ul>
                  <li>
                    <strong>Menú Principal:</strong> Incluye accesos rápidos a
                    funciones importantes como Home, Facturas, Nueva Factura,
                    Nueva Cotización, Nuevo Producto, y Reportar Gastos Caja.
                  </li>
                  <li>
                    <strong>Notificaciones y Configuración:</strong> En la parte
                    superior derecha, se encuentran los iconos de notificaciones
                    y ajustes, permitiendo al usuario acceder a mensajes
                    importantes y modificar configuraciones del sistema.
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <div className="row mt-4">
            <h4>Encabezado de Factura</h4>
            <section className="docs-intro">
              <div className="row mt-4">
                <ul>
                  <li>
                    <strong>Número de Factura: </strong>
                    Indica el número único asignado a la factura actual.
                  </li>
                  <li>
                    <strong>Fecha de Facturación: </strong>
                    Permite seleccionar la fecha de emisión de la factura.
                  </li>
                  <li>
                    <strong>Tipo de Factura: </strong>
                    Selecciona el tipo de factura, como "Factura de Consumidor".
                  </li>
                  <li>
                    <strong>RNC o Cédula: </strong>
                    Campo para ingresar el RNC o cédula del cliente.
                  </li>
                  <li>
                    <strong>Buscar Cliente: </strong>
                    Botón para buscar y seleccionar un cliente de la base de
                    datos.
                  </li>
                  <li>
                    <strong>Email Cliente: </strong>
                    Campo para ingresar el correo electrónico del cliente.
                  </li>
                  <li>
                    <strong>Método de Pago: </strong>
                    Selecciona el método de pago utilizado, como efectivo,
                    tarjeta, etc.
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <div className="row mt-4">
            <h4>Detalle de Productos/Servicios</h4>
            <section className="docs-intro">
              <div className="row mt-4">
                <ul>
                  <li>
                    <strong>Producto/Servicio: </strong>
                    Lista los productos o servicios agregados a la factura.
                  </li>
                  <li>
                    <strong>Código: </strong>
                    Muestra el código del producto o servicio.
                  </li>
                  <li>
                    <strong>Cantidad: </strong>
                    Campo para ingresar la cantidad de cada producto o servicio.
                  </li>
                  <li>
                    <strong>Descuento (%): </strong>
                    Permite aplicar un porcentaje de descuento a cada ítem.
                  </li>
                  <li>
                    <strong>Impuesto: </strong>
                    Muestra el impuesto aplicado a cada ítem.
                  </li>
                  <li>
                    <strong>Precio: </strong>
                    Muestra el precio unitario de cada ítem.
                  </li>
                  <li>
                    <strong>Total: </strong>
                    Calcula el total por ítem, considerando cantidad, descuento
                    e impuestos.
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <div className="row mt-4">
            <h4>Acciones en Facturación</h4>
            <section className="docs-intro">
              <div className="row mt-4">
                <ul>
                  <li>
                    <strong>Facturar: </strong>
                    Generar y registrar la factura.
                  </li>
                  <li>
                    <strong>Factura Pendiente: </strong>
                    Guardar la factura para continuar más adelante.
                  </li>
                  <li>
                    <strong>Cantidad: </strong>
                    Campo para ingresar la cantidad de cada producto o servicio.
                  </li>
                  <li>
                    <strong>Crear Cotización: </strong>
                    Convertir el detalle actual en una cotización.
                  </li>
                  <li>
                    <strong>Vista Previa: </strong>
                    Mostrar una previsualización de la factura.
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <div className="row mt-4">
            <h4>Descuentos y Recargos:</h4>
            <section className="docs-intro">
              <div className="row mt-4">
                <ul>
                  <li>
                    <strong>Descuento general: </strong>
                    Sección donde puedes agregar un descuento general, ya sea en
                    porcentaje o monto fijo.
                  </li>
                  <li>
                    <strong>Aplicar otros cargos: </strong>
                    Permite añadir cargos adicionales con una descripción y
                    monto.
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <div className="row mt-4">
            <h4>Descuentos y Recargos:</h4>
            <section className="docs-intro">
              <div className="row mt-4">
                <ul>
                  <li>
                    <strong>Sub-total: </strong>
                    Muestra el subtotal de la factura sin incluir impuestos ni
                    descuentos.
                  </li>
                  <li>
                    <strong>Descuentos: </strong>
                    Total de descuentos aplicados.
                  </li>
                  <li>
                    <strong>Itbis: </strong>
                    Muestra el valor correspondiente al impuesto de ITBIS.
                  </li>
                  <li>
                    <strong>Total: </strong>
                    Monto total a pagar, luego de aplicar descuentos e
                    impuestos.
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <h5>Proximo paso - Crear una factura:</h5>
          <p>
            <a href="/"> proximo paso {">>>"}</a>
          </p>
        </header>
      </article>
    </>
  );
};
export default Invoice;
