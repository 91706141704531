import SEO from "../../components/seo";

const Config = (props) => {
  return (
    <>
      <SEO
        title="ProVenta - configuración de empresa"
        description="manual de paso a paso de como configurar tu empresa para empezar a facturar"
        name="ProVenta"
        type="article"
      />
      <article className="docs-article" id="section-1">
        <header className="docs-header">
          <h1 className="docs-heading">Información de la empresa </h1>
          <section className="docs-intro">
            <div className="card mb-4" style={{ width: "62rem" }}>
              <img
                src="/assets/configuracion.png"
                className="card-img-top"
                alt="pantalla configuración empresa"
              />
              <div className="card-body">
                <p className="card-text">
                  Clic en el botón "Configuración" para ver opciones
                </p>
              </div>
            </div>
            <p>
              En esta sección, podrás personalizar y ajustar todas las opciones
              necesarias para adaptar <strong>ProVenta</strong> a las
              necesidades específicas de tu empresa. Configura parámetros
              esenciales, como preferencias de facturación, gestión de
              inventario y notificaciones, para optimizar el rendimiento de tu
              negocio.
            </p>
          </section>
          <div className="row mt-4">
            <h2 className="docs-heading">Opciones de configuración</h2>
            <section className="docs-intro">
              <div className="card mb-4" style={{ width: "62rem" }}>
                <img
                  src="/assets/opciones_configuracion.png"
                  className="card-img-top"
                  alt="pantalla opciones de configuración"
                />
                <div className="card-body">
                  <p className="card-text">
                    En esta sección, te guiaremos paso a paso para completar la
                    configuración básica de tu empresa. Sigue las instrucciones
                    a continuación para asegurarte de que todos los campos estén
                    correctamente llenados.
                  </p>
                </div>
              </div>
              <div className="row mt-4">
                <ol>
                  <li>
                    <strong>Nombre de la empresa:</strong> Introduce el nombre
                    oficial de tu empresa. Este nombre aparecerá en todas las
                    facturas y documentos generados por el sistema.
                  </li>
                  <li>
                    <strong>Nombre comercial:</strong> Si tu empresa opera bajo
                    un nombre diferente al oficial, ingrésalo aquí. Este nombre
                    también aparecerá en tus facturas. <br />
                    <strong>Nota: </strong>si el nombre comercial es diferente
                    al nombre de la empresa al momento de imprimir ambos serán
                    visibles.
                  </li>
                  <li>
                    <strong>Encargado:</strong> Escribe el nombre de la persona
                    responsable de gestionar el sistema. Este puede ser el dueño
                    de la empresa o un administrador designado.
                  </li>
                  <li>
                    <strong>Tipo de empresa:</strong> Selecciona el tipo de
                    empresa desde el menú desplegable. Las opciones pueden
                    incluir "Individual de responsabilidad limitada", "Sociedad
                    anónima", entre otras.
                  </li>
                  <li>
                    <strong>RNC:</strong> Introduce el Registro Nacional de
                    Contribuyentes (RNC) de tu empresa. Este número es esencial
                    para emitir facturas con valor fiscal.
                  </li>
                  <li>
                    <strong>Número de impresión fiscal (NIF):</strong> Si tu
                    empresa tiene un número de impresión fiscal, introdúcelo
                    aquí. Este es otro identificador fiscal importante.
                  </li>
                  <li>
                    <strong>Teléfono:</strong> Ingresa el número de teléfono
                    principal de tu empresa. Este número será utilizado para
                    contactar a tu empresa.
                  </li>
                  <li>
                    <strong>Teléfono de contacto:</strong> Introduce un número
                    de teléfono adicional para contacto, si es diferente del
                    número principal. Este puede ser un número directo del
                    encargado o de atención al cliente.
                  </li>
                  <li>
                    <strong>Correo electrónico:</strong> Proporciona la
                    dirección de correo electrónico oficial de tu empresa. Este
                    correo se utilizará para enviar y recibir notificaciones
                    importantes.
                  </li>
                  <li>
                    <strong>Logo:</strong> Sube el logo de tu empresa. El tamaño
                    recomendado es 150x150 píxeles. Este logo aparecerá en tus
                    facturas y otros documentos generados por el sistema. (solo
                    se permiten imágenes con formato, png, jpg, jpeg, webp, gif)
                  </li>
                </ol>
              </div>
            </section>
            <section className="docs-section">
              <h2 className="section-heading">Dirección empresa</h2>
              <div className="card mb-4" style={{ width: "62rem" }}>
                <img
                  src="/assets/empresa_direccion.png"
                  className="card-img-top"
                  alt="pantalla opciones de configuración"
                />
                <div className="card-body">
                  En esta sección, te guiaremos paso a paso para completar la
                  configuración de la dirección de tu empresa. Sigue las
                  instrucciones a continuación para asegurarte de que todos los
                  campos estén correctamente llenados.
                </div>
              </div>
              <div>
                <ol>
                  <li>
                    <strong>Dirección:</strong> Introduce la dirección física de
                    tu empresa. Asegúrate de incluir el número y nombre de la
                    calle, así como cualquier detalle adicional necesario.
                  </li>
                  <li>
                    <strong>Provincia:</strong> Selecciona la provincia donde se
                    encuentra ubicada tu empresa desde el menú desplegable. Este
                    campo es importante para la identificación geográfica y
                    legal de tu empresa.
                  </li>
                  <li>
                    <strong>Sector:</strong> Escribe el nombre del sector o
                    barrio donde está localizada tu empresa. Esta información
                    ayuda a especificar aún más la ubicación dentro de la
                    provincia..
                  </li>
                </ol>
              </div>
            </section>
            <section className="docs-section">
              <h2 className="section-heading">Opciones de facturación</h2>
              <div className="card mb-4" style={{ width: "62rem" }}>
                <img
                  src="/assets/opciones_facturacion.png"
                  className="card-img-top"
                  alt="pantalla opciones de facturación"
                />
                <div className="card-body">
                  Guía paso a paso para completar la configuración de las
                  opciones de facturación de tu empresa. Sigue las instrucciones
                  a continuación para asegurarte de que todos los campos estén
                  correctamente llenados.
                </div>
              </div>

              <div>
                <ol>
                  <li>
                    <strong>Slogan:</strong> Introduce un eslogan que represente
                    a tu empresa. Este eslogan aparecerá en todas las facturas
                    emitidas. Ejemplo: "Simplifica tu negocio con nosotros".
                  </li>
                  <li>
                    <strong>Coletilla de factura:</strong> Escribe un mensaje
                    que se incluirá al final de cada factura. Este mensaje puede
                    ser una nota de agradecimiento o una invitación a regresar.
                    Ejemplo: "Gracias por su compra, regrese pronto".
                  </li>
                  <li>
                    <strong>Términos y condiciones:</strong> Aquí puedes agregar
                    cualquier término y condición que desees incluir en tus
                    facturas. Este campo es opcional pero recomendable para
                    asegurar claridad con tus clientes.
                  </li>
                  <li>
                    <strong>Notas:</strong> Utiliza este campo para añadir
                    cualquier nota adicional que desees incluir en tus facturas.
                    Este campo también es opcional.
                  </li>
                </ol>
              </div>
              <h5 className="mt-5">Opciones de Facturación (interruptores):</h5>
              <div>
                <ol>
                  <li>
                    <strong>Imprime factura:</strong> Activa esta opción si
                    deseas que el sistema imprima una factura automáticamente
                    después de cada transacción.
                  </li>
                  <li>
                    <strong>Requiere cliente para facturar:</strong> Activa esta
                    opción si deseas que se ingrese la información del cliente
                    antes de generar una factura.
                  </li>
                  <li>
                    <strong>Crédito a clientes:</strong> Activa esta opción si
                    tu empresa ofrece crédito a los clientes.
                  </li>
                  <li>
                    <strong>Facturación sin stock:</strong> Activa esta opción
                    si deseas permitir la facturación de productos que no están
                    en inventario.
                  </li>
                  <li>
                    <strong>
                      Envía factura por correo electrónico al cliente:
                    </strong>
                    Activa esta opción para que el sistema envíe automáticamente
                    una copia de la factura al correo electrónico del cliente.
                  </li>
                  <li>
                    <strong>Cambiar fecha de factura:</strong> Activa esta
                    opción si deseas permitir el cambio de la fecha en la
                    factura.
                  </li>
                  <li>
                    <strong>Requiere NCF para facturar:</strong> Activa esta
                    opción si es obligatorio incluir un Número de Comprobante
                    Fiscal (NCF) en cada factura.
                  </li>
                  <li>
                    <strong>Requiere empleado para facturar: </strong>Activa
                    esta opción si deseas que se ingrese la información del
                    empleado que realiza la facturación.
                  </li>
                  <li>
                    <strong>Disponible empleado para facturar: </strong>Activa
                    esta opción para que el sistema muestre una lista de
                    empleados disponibles para realizar la facturación.
                  </li>
                  <li>
                    <strong>Imprimir cliente en factura: </strong> Activa esta
                    opción si deseas que el nombre del cliente aparezca en la
                    factura impresa.
                  </li>
                  <li>
                    <strong>Imprimir forma de pago en la factura: </strong>{" "}
                    Activa esta opción para que la forma de pago se incluya en
                    la factura.
                  </li>
                  <li>
                    <strong>No puede facturar sin abrir caja: </strong>Activa
                    esta opción para que el sistema no permita facturar si la
                    caja no está abierta.
                  </li>
                  <li>
                    <strong>Imprimir imagen en Facturas/Cotizaciones: </strong>
                    Activa esta opción si deseas que se impriman imágenes en las
                    facturas y cotizaciones (solo si hay imágenes disponibles).
                  </li>
                </ol>
              </div>
            </section>
            <section className="docs-section">
              <h2 className="section-heading">
                Configuración de Opciones de Impresión
              </h2>
              <div className="card mb-4" style={{ width: "62rem" }}>
                <img
                  src="/assets/opciones_impresion.png"
                  className="card-img-top"
                  alt="pantalla opciones de impresion"
                />
                <div className="card-body">
                  En esta sección, te explicaremos cómo configurar las opciones
                  de impresión en el sistema. Sigue las instrucciones a
                  continuación para ajustar las configuraciones según las
                  necesidades de tu empresa.
                </div>
              </div>
              <h5 className="mt-5">Opciones:</h5>
              <div>
                <ol>
                  <li>
                    <strong>Tipo de salida por defecto:</strong> Selecciona el
                    dispositivo de salida predeterminado para la impresión de
                    facturas. Las opciones incluyen impresora, PDF, etc.
                  </li>
                  <li>
                    <strong>Formato de salida por defecto:</strong> Elige el
                    formato de salida que se utilizará por defecto. Ejemplo:
                    Punto de Venta.
                  </li>
                  <li>
                    <strong>Tamaño papel:</strong> Selecciona el tamaño del
                    papel que utilizarás para imprimir las facturas. Ejemplo:
                    80mm x 210mm.
                  </li>
                  <li>
                    <strong>Tamaño orientación::</strong> Selecciona la
                    orientación del papel. Las opciones incluyen Portrait
                    (vertical) y Landscape (horizontal).
                  </li>
                </ol>
              </div>
              <h5 className="mt-5">Configuración de Márgenes:</h5>
              <div>
                <ol>
                  <li>
                    <strong>Margen superior:</strong> Especifica el tamaño del
                    margen superior en milímetros. Ejemplo: 1 mm.
                  </li>
                  <li>
                    <strong>Margen izquierdo:</strong> Especifica el tamaño del
                    margen izquierdo en milímetros. Ejemplo: 13 mm.
                  </li>
                  <li>
                    <strong>Margen derecho:</strong> Especifica el tamaño del
                    margen derecho en milímetros. Ejemplo: 0 mm.
                  </li>
                  <li>
                    <strong>Margen de pie de página:</strong> Especifica el
                    tamaño del margen inferior en milímetros. Ejemplo: 2 mm.
                  </li>
                </ol>
              </div>
              <h5 className="mt-5">Opciones Adicionales:</h5>
              <div>
                <ol>
                  <li>
                    <strong>Imprime logo en factura punto de venta:</strong>{" "}
                    Activa esta opción si deseas que el logo de la empresa
                    aparezca en las facturas del punto de venta.
                  </li>
                  <li>
                    <strong>Imprime logo en factura estándar:</strong> Activa
                    esta opción si deseas que el logo de la empresa aparezca en
                    las facturas estándar.
                  </li>
                </ol>
              </div>
            </section>
          </div>
          <h5>
            <a href="/configuracion">Proximo paso - funciones principales:</a>
          </h5>
          <p>
            Exploraremos las funciones principales de que te ayudarán a
            gestionar tu negocio de manera más eficiente, están diseñadas para
            simplificar tus tareas diarias, ahorrar tiempo y dinero,
            permitiéndote centrarte en el crecimiento de tu negocio. &nbsp;
            <a href="/configuracion">proximo paso {">>>"}</a>
          </p>
        </header>
      </article>
    </>
  );
};
export default Config;
