export default function Header() {
  return (
    <header className="header fixed-top">
      <div className="branding">
        <div className="container-fluid position-relative">
          <nav className="navbar navbar-expand-lg">
            <div className="site-logo">
              <a className="navbar-brand" href="/inicio">
                <img
                  className="logo-icon me-2"
                  src="assets/images/site-logo.svg"
                  alt="logo"
                />
                <span className="logo-text">
                  Pro<span className="text-alt">Venta</span>
                </span>
              </a>
            </div>

            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navigation"
              aria-controls="navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span> </span>
              <span> </span>
              <span> </span>
            </button>

            <div
              className="collapse navbar-collapse py-3 py-lg-0"
              id="navigation"
            >
              <ul className="social-list list-inline mt-3 mt-lg-0 mb-lg-0 d-none d-xl-flex ms-lg-3 me-lg-3">
                <li className="list-inline-item">
                  <a href="https://github.com/Isidroca">
                    <i className="fab fa-github fa-fw"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://do.linkedin.com/company/proventa-pos">
                  <i className="fab fa-linkedin"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/proventa.rd">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/sistemaproventa/">
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav ms-lg-auto">
              <li className="nav-item dropdown me-lg-4">
                  <a
                    className="nav-link "
                    href="/introduccion"
                    id="navbarDropdown"
                    
                    
                  >
                    Documentación
                  </a>
                  <ul
                    className="dropdown-menu rounded shadow menu-animate slideIn"
                    aria-labelledby="navbarDropdown"
                    style={{ display: "none"}}
                  >
                    <li>
                      <a className="dropdown-item has-icon" href="docs.html">
                        <span className="theme-icon-holder me-2">
                          <i className="fas fa-home fa-fw"></i>
                        </span>
                        Docs Home
                      </a>
                    </li>

                    <li>
                      <div className="dropdown-divider m-0"></div>
                    </li>

                    <li>
                      <a
                        className="dropdown-item has-icon"
                        href="docs-page.html#section-1"
                      >
                        <span className="theme-icon-holder me-2">
                          <i className="fas fa-map-signs fa-fw"></i>
                        </span>
                        Introduction
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item has-icon"
                        href="docs-page.html#section-2"
                      >
                        <span className="theme-icon-holder me-2">
                          <i className="fas fa-arrow-down fa-fw"></i>
                        </span>
                        Installation
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item has-icon"
                        href="docs-page.html#section-3"
                      >
                        <span className="theme-icon-holder me-2">
                          <i className="fas fa-box fa-fw"></i>
                        </span>
                        APIs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item has-icon"
                        href="docs-page.html#section-4"
                      >
                        <span className="theme-icon-holder me-2">
                          <i className="fas fa-cogs fa-fw"></i>
                        </span>
                        Integrations
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item has-icon"
                        href="docs-page.html#section-5"
                      >
                        <span className="theme-icon-holder me-2">
                          <i className="fas fa-tools fa-fw"></i>
                        </span>
                        Utilities
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item has-icon"
                        href="docs-page.html#section-6"
                      >
                        <span className="theme-icon-holder me-2">
                          <i className="fas fa-laptop-code fa-fw"></i>
                        </span>
                        Web
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item has-icon"
                        href="docs-page.html#section-7"
                      >
                        <span className="theme-icon-holder me-2">
                          <i className="fas fa-tablet-alt fa-fw"></i>
                        </span>
                        Mobile
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item has-icon"
                        href="docs-page.html#section-8"
                      >
                        <span className="theme-icon-holder me-2">
                          <i className="fas fa-book-reader fa-fw"></i>
                        </span>
                        Resources
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item has-icon"
                        href="docs-page.html#section-9"
                      >
                        <span className="theme-icon-holder me-2">
                          <i className="fas fa-lightbulb fa-fw"></i>
                        </span>
                        FAQs
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item me-lg-4" style={{ display: "none" }}>
                  <a className="nav-link" href="/">
                    Módulos
                  </a>
                </li>
                <li className="nav-item me-lg-4">
                  <a className="nav-link" href="/planes">
                    Planes
                  </a>
                </li>
                
                <li className="nav-item me-lg-4">
                  <a className="nav-link" href="/contacto">
                    Contacto
                  </a>
                </li>
                <li className="nav-item me-lg-4">
                  <a className="nav-link" href="http://proventa.app">
                  <i className="fas fa-home"></i> Pagina principal
                  </a>
                </li>
                <li className="nav-item me-lg-0 mt-3 mt-lg-0">
                  <a className="btn btn-primary text-white" href="http://proventa.app">
                  <i className="fas fa-download"></i> Descargar
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
