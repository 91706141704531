import SEO from "../../components/seo";

const Inventory = (props) => {
  return (
    <>
      <SEO
        title="Gestión Integral de Inventario - Controla tus Productos con ProVenta"
        description="Administra y controla tu inventario de forma eficiente con el módulo de inventario de ProVenta. Mantén un seguimiento preciso de existencias, movimientos y niveles de stock en tiempo real. Optimiza tus operaciones y evita faltantes con nuestro sistema avanzado de gestión de inventario."
        name="ProVenta"
        type="article"
      />
      <article className="docs-article" id="section-1">
        <header className="docs-header">
          <h1 className="docs-heading">Inventario </h1>
          <section className="docs-intro">
            <div className="card mb-4" style={{ width: "50rem" }}>
              <img
                src="/assets/inventario.png"
                className="card-img-top"
                alt="modulo de inventario"
              />
              <div className="card-body">
                <p className="card-text">Pantalla del modulo de inventario</p>
              </div>
            </div>
            <p>
              El módulo de inventario en un sistema de facturación como ProVenta
              es esencial para gestionar de manera eficiente los productos y sus
              movimientos dentro de la empresa. Este módulo permite a los
              usuarios llevar un control detallado de los artículos en stock, su
              disponibilidad, y las entradas y salidas del inventario. Además,
              facilita el registro de productos, incluyendo detalles como
              código, descripción, categorías, precios de compra y venta, y
              niveles mínimos de stock para evitar desabastecimientos.
            </p>
          </section>
          <div className="row mt-4">
            <h4>
              El módulo de inventario incluye las siguientes funcionalidades
              claves:
            </h4>
            <section className="docs-intro">
              <div className="row mt-4">
                <ul>
                  <li>
                    <strong>Gestión de productos:</strong> Agregar, editar y
                    eliminar productos con toda la información relevante como el
                    precio, descripción, código de barras y categoría..
                  </li>
                  <li>
                    <strong>Movimientos de inventario: </strong> Registrar las
                    entradas y salidas de productos, ya sea por compras, ventas
                    o ajustes manuales, permitiendo una trazabilidad completa de
                    los movimientos.
                  </li>
                  <li>
                    <strong>Alertas de stock mínimo:</strong>
                    Configurar niveles mínimos de inventario para recibir
                    alertas cuando un producto esté cerca de agotarse, ayudando
                    a prevenir pérdidas por falta de inventario.
                  </li>
                  <li>
                    <strong>Reporte de existencias:</strong>
                    Generar informes en tiempo real sobre el estado del
                    inventario, mostrando productos en exceso, con poca
                    disponibilidad o sin movimiento durante un período
                    determinado.
                  </li>
                  <li>
                    <strong>Integración con proveedores:</strong>
                    Facilitar la generación automática de órdenes de compra a
                    proveedores cuando los niveles de stock lleguen a su punto
                    mínimo.
                  </li>
                  <li>
                    <strong>Historial de transacciones: </strong>
                    Mantener un registro de todas las operaciones que afecten el
                    inventario, permitiendo consultar cambios y realizar
                    auditorías internas.
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <h5>Proximo paso - Crear una factura:</h5>
          <p>
            <a href="/"> proximo paso {">>>"}</a>
          </p>
        </header>
      </article>
    </>
  );
};
export default Inventory;
