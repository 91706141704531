import SEO from "../components/seo";

const Plans = (props) => {
  return (
    <>
    <SEO
        title="ProVenta - elije el plan que mas se adapte a tu negocio"
        description="Tenemos planes que se ajustan a todos los presupuestos, tienes un mes de uso gratis en cada plan"
        name="ProVenta"
        type="article"
      />
      <div className="page-header theme-bg-dark py-5 text-center position-relative">
        <div className="theme-bg-shapes-right"></div>
        <div className="theme-bg-shapes-left"></div>
        <div className="container">
          <h1 className="page-heading single-col-max mx-auto">
            ¡Un plan para cada presupuesto!
          </h1>
          <div className="page-intro single-col-max mx-auto">
            Prueba GRATIS de 30 días con todos los planes. Cancela en cualquier
            momento.
          </div>
        </div>
      </div>
      <div className="page-content py-5">
        <section className="plans-section mb-5 pb-5">
          <div className="container">
            <div
              className="plan-items row justify-content-center"
              id="plan-items"
            >
              <div className="col-12 col-md-6 col-lg-3 mb-5 mb-lg-0">
                <div className="item shadow rounded">
                  <div className="plan-header text-center">
                    <h3 className="plan-title">Emprendedor</h3>
                    <div className="plan-cost">
                      <div className="plan-cost-monthly plan-cost-option">
                        <span className="number">RD$1,800</span>
                      </div>
                    </div>
                    <div className="plan-cta">
                      <a className="btn-primary btn" href="http://proventa.app">
                        Obtener
                      </a>
                    </div>
                  </div>

                  <div className="plan-content">
                    <div className="plan-intro">
                      La mejor opción para uso personal o negocios pequeños
                    </div>
                    <ul className="plan-desc list-unstyled">
                      <li>
                        Usuarios
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          2
                        </div>
                      </li>
                      <li>
                        Empleados
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          5
                        </div>
                      </li>
                      <li>
                        Soporte técnico
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          L-V
                        </div>
                      </li>
                      <li>
                        Imágenes productos
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          1
                        </div>
                      </li>
                      <li>
                        Facturas
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitadas"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          I
                        </div>
                      </li>
                      <li>
                        Clientes
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitados"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          I
                        </div>
                      </li>

                      <li>
                        Ingresos
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitados"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          I
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-5 mb-lg-0">
                <div className="item shadow rounded">
                  <div className="plan-header text-center">
                    <h3 className="plan-title">Básico</h3>
                    <div className="plan-cost">
                      <div className="plan-cost-monthly plan-cost-option">
                        <span className="number">RD$2,300</span>
                      </div>
                    </div>
                    <div className="plan-cta">
                      <a className="btn-primary btn" href="http://proventa.app">
                        Obtener
                      </a>
                    </div>
                  </div>

                  <div className="plan-content">
                    <div className="plan-intro">
                      Ideal para PYME o para comercios en continua evolución y
                      crecimiento
                    </div>
                    <ul className="plan-desc list-unstyled">
                      <li>
                        Usuarios
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          5
                        </div>
                      </li>
                      <li>
                        Empleados
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          15
                        </div>
                      </li>
                      <li>
                        Soporte técnico
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          24/7
                        </div>
                      </li>
                      <li>
                        Imágenes productos
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          3
                        </div>
                      </li>
                      <li>
                        Facturas
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitadas"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          I
                        </div>
                      </li>
                      <li>
                        Clientes
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitados"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          I
                        </div>
                      </li>

                      <li>
                        Asistencia personalizada
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          Si
                        </div>
                      </li>
                      <li>
                        Multiples cajeros
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          Si
                        </div>
                      </li>
                      <li>
                        Ingresos
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitados"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          I
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-5 mb-lg-0">
                <div className="item shadow rounded">
                  <div className="plan-header text-center">
                    <h3 className="plan-title">Enterprise</h3>
                    <div className="plan-cost">
                      <div className="plan-cost-monthly plan-cost-option">
                        <span className="number">RD$6,500</span>
                      </div>
                    </div>
                    <div className="plan-cta">
                      <a className="btn-primary btn" href="http://proventa.app">
                        Obtener
                      </a>
                    </div>
                  </div>

                  <div className="plan-content">
                    <div className="plan-intro">
                      Mejor opción para comercios mas grandes que manejan gran
                      volumen de datos
                    </div>
                    <ul className="plan-desc list-unstyled">
                      <li>
                        Usuarios
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitados"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          I
                        </div>
                      </li>
                      <li>
                        Empleados
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitados"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          I
                        </div>
                      </li>
                      <li>
                        Soporte técnico
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          24/7
                        </div>
                      </li>
                      <li>
                        Imágenes productos
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          6
                        </div>
                      </li>
                      <li>
                        Facturas
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitadas"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          I
                        </div>
                      </li>
                      <li>
                        Clientes
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitados"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          I
                        </div>
                      </li>
                      <li>
                        Reportes proyecciones de ventas
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          Si
                        </div>
                      </li>
                      <li>
                        Asistencia personalizada
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          Si
                        </div>
                      </li>
                      <li>
                        Multiples cajeros
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          Si
                        </div>
                      </li>
                      <li>
                        Ingresos
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitados"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          I
                        </div>
                      </li>
                      <li>
                        Ventas en linea
                        <div
                          tabIndex="0"
                          className="theme-icon-holder info-popover-trigger"
                          role="button"
                          title="Ilimitados"
                          data-bs-toggle="popover"
                          data-bs-content="You can use popover to give more info about the feature."
                          data-bs-container="body"
                          data-bs-trigger="focus"
                        >
                          Si
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq-section py-5">
          <div className="container single-col-max">
            <h3 className="text-center mb-5">Preguntas Frecuentes</h3>

            <div
              id="faq-accordion"
              className="faq-accordion accordion mx-auto text-start"
            >
              <div className="accordion-item mb-4 shadow-sm rounded">
                <h2
                  className="accordion-header border-0 px-4 py-3 rounded"
                  id="faq-heading-1"
                >
                  <svg
                    className="svg-inline--fa fa-circle-question"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="circle-question"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                    ></path>
                  </svg>
                  <button
                    className="accordion-button btn btn-link"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq1"
                    aria-expanded="false"
                    aria-controls="faq1"
                  >
                    ¿Cómo sé qué plan es el adecuado para mí?
                    <svg
                      className="svg-inline--fa fa-chevron-down"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="faq1"
                  className="accordion-collapse collapse border-0"
                  aria-labelledby="faq-heading-1"
                >
                  <div className="accordion-body text-start p4">
                    Al elegir el plan adecuado para ti, es importante considerar
                    tus necesidades específicas y el tamaño de tu negocio.
                    ProVenta ofrece diferentes planes que se adaptan a distintos
                    niveles de operación. Aquí hay algunos factores a tener en
                    cuenta al decidir qué plan es el adecuado para ti:
                    <div>
                      <ul>
                        <li className="mt-4">
                          Tamaño del negocio: Evalúa el tamaño de tu negocio en
                          términos de ventas, número de productos y clientes. Si
                          tienes un negocio pequeño con volúmenes de facturación
                          y niveles de inventario limitados, es posible que un
                          plan básico sea suficiente. Para negocios más grandes
                          con un alto volumen de transacciones y un inventario
                          extenso, es posible que un plan más completo y
                          escalable sea la mejor opción.
                        </li>
                        <li className="mt-4">
                          Funcionalidades requeridas: Revisa las funcionalidades
                          que necesitas para tu negocio. Cada plan de ProVenta
                          ofrece diferentes características y capacidades.
                          Considera si necesitas características adicionales
                          como control de gastos, reportes detallados,
                          integraciones con otros sistemas, entre otros. Evalúa
                          qué funcionalidades son esenciales para tu negocio y
                          busca un plan que las incluya.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item mb-4 shadow-sm rounded">
                <h2
                  className="accordion-header border-0 px-4 py-3 rounded"
                  id="faq-heading-2"
                >
                  <svg
                    className="svg-inline--fa fa-circle-question"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="circle-question"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                    ></path>
                  </svg>
                  <button
                    className="accordion-button btn btn-link"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq2"
                    aria-expanded="false"
                    aria-controls="faq2"
                  >
                    ¿Existen compromisos a largo plazo o tarifas de cancelación?
                    <svg
                      className="svg-inline--fa fa-chevron-down"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="faq2"
                  className="accordion-collapse collapse border-0"
                  aria-labelledby="faq-heading-2"
                >
                  <div className="accordion-body text-start p4">
                    No hay compromisos a largo plazo ni pagos por cancelación
                  </div>
                </div>
              </div>

              <div className="accordion-item mb-4 shadow-sm rounded">
                <h2
                  className="accordion-header border-0 px-4 py-3 rounded"
                  id="faq-heading-3"
                >
                  <svg
                    className="svg-inline--fa fa-circle-question"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="circle-question"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                    ></path>
                  </svg>
                  <button
                    className="accordion-button btn btn-link"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq3"
                    aria-expanded="false"
                    aria-controls="faq3"
                  >
                    ¿Cómo cancelo mi suscripción?
                    <svg
                      className="svg-inline--fa fa-chevron-down"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="faq3"
                  className="accordion-collapse collapse border-0"
                  aria-labelledby="faq-heading-3"
                >
                  <div className="accordion-body text-start p4">
                    <div className="accordion-body pt-0">
                      <p>
                        Cancelar tu suscripción mensual en ProVenta es un
                        proceso sencillo. Sigue estos pasos:
                      </p>
                      <p className="mb-0">
                        <div>
                          <ol>
                            <li className="mt-1">
                              Inicia sesión en tu cuenta de ProVenta utilizando
                              tus credenciales de acceso.
                            </li>
                            <li className="mt-1">
                              Una vez que hayas iniciado sesión, busca la
                              sección de "Configuración" o "Cuenta" en el menú
                              principal. Puede variar según la interfaz de
                              usuario de ProVenta.
                            </li>
                            <li className="mt-1">
                              Dentro de la sección de configuración o cuenta,
                              busca la opción de "Suscripción" o "Facturación".
                              Haz clic en esa opción para acceder a los detalles
                              de tu suscripción actual.
                            </li>
                            <li className="mt-1">
                              Dentro de los detalles de la suscripción, deberías
                              encontrar la opción para cancelar la suscripción,
                              "Cancelar suscripción" o algo similar. Haz clic en
                              esa opción.
                            </li>
                          </ol>
                        </div>
                      </p>
                      <p>
                        Es importante tener en cuenta que al cancelar tu
                        suscripción mensual, perderás el acceso a las
                        funcionalidades y características de ProVenta una vez
                        finalizado el período de facturación actual.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item mb-4 shadow-sm rounded">
                <h2
                  className="accordion-header border-0 px-4 py-3 rounded"
                  id="faq-heading-4"
                >
                  <svg
                    className="svg-inline--fa fa-circle-question"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="circle-question"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                    ></path>
                  </svg>
                  <button
                    className="accordion-button btn btn-link"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq4"
                    aria-expanded="false"
                    aria-controls="faq4"
                  >
                    ¿Qué tipo de entrenamiento o soporte recibo?
                    <svg
                      className="svg-inline--fa fa-chevron-down"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="faq4"
                  className="accordion-collapse collapse border-0"
                  aria-labelledby="faq-heading-4"
                >
                  <div className="accordion-body text-start p4">
                    <p>
                      Nuestro objetivo es brindarte el soporte necesario para
                      garantizar tu éxito. Si tienes necesidades específicas de
                      entrenamiento o requieres un nivel más personalizado de
                      soporte estaremos ahí para ti.
                    </p>
                    <p className="mb-0">
                      Recuerda que estamos comprometidos a ayudarte en cada
                      paso.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item mb-4 shadow-sm rounded">
                <h2
                  className="accordion-header border-0 px-4 py-3 rounded"
                  id="faq-heading-5"
                >
                  <svg
                    className="svg-inline--fa fa-circle-question"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="circle-question"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                    ></path>
                  </svg>
                  <button
                    className="accordion-button btn btn-link"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq5"
                    aria-expanded="false"
                    aria-controls="faq5"
                  >
                    ¿Recibo actualizaciones periódicas?
                    <svg
                      className="svg-inline--fa fa-chevron-down"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="faq5"
                  className="accordion-collapse collapse border-0"
                  aria-labelledby="faq-heading-5"
                >
                  <div className="accordion-body text-start p4">
                    Sí, ofrecemos actualizaciones periódicas para mantener tu
                    sistema siempre al día. Estas actualizaciones incluyen
                    mejoras de funcionalidad, correcciones de errores y nuevas
                    características para asegurar que tu experiencia con nuestro
                    servicio sea óptima. Te notificaremos sobre cualquier
                    actualización importante y te proporcionaremos instrucciones
                    claras sobre cómo implementarlas.
                  </div>
                </div>
              </div>

              <div className="accordion-item mb-4 shadow-sm rounded">
                <h2
                  className="accordion-header border-0 px-4 py-3 rounded"
                  id="faq-heading-6"
                >
                  <svg
                    className="svg-inline--fa fa-circle-question"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="circle-question"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                    ></path>
                  </svg>
                  <button
                    className="accordion-button btn btn-link"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq6"
                    aria-expanded="false"
                    aria-controls="faq6"
                  >
                    ¿Qué pasa cuando vence mi suscripción?
                    <svg
                      className="svg-inline--fa fa-chevron-down"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="faq6"
                  className="accordion-collapse collapse border-0"
                  aria-labelledby="faq-heading-6"
                >
                  <div className="accordion-body text-start p4">
                    Cuando tu suscripción vence, se te notificará con antelación
                    para que puedas renovar tu plan sin interrupciones. Te
                    recomendamos renovar tu suscripción antes de la fecha de
                    vencimiento para evitar interrupciones en el servicio y
                    continuar disfrutando de todas las ventajas que ofrecemos.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Plans;
