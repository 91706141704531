import { Link } from "react-router-dom";
import SEO from "../../components/seo";

const Features = (props) => {
  return (
    <>
      <SEO
        title="Módulos del Sistema de Facturación - ProVenta | Gestión Completa de Ventas e Inventario"
        description="Descubre los módulos del sistema de facturación ProVenta: facturación, gestión de inventario, reportes, cotizaciones, y más. Una solución integral para mejorar la administración de tu negocio de forma eficiente y en tiempo real."
        name="ProVenta"
        type="article"
      />
      <article className="docs-article" id="section-1">
        <header className="docs-header">
          <h1 className="docs-heading">Módulos del Sistema </h1>
          <section className="docs-intro">
            <p>
              Bienvenido a <strong>ProVenta</strong>, tu solución integral para
              la gestión eficiente de facturación e inventario. Simplifica y
              optimiza tus operaciones empresariales con nuestras herramientas
              fáciles de usar.
            </p>
          </section>
          <div className="row mt-4">
            <section className="docs-intro">
              <div className="row g-3 py-6 row-cols-1 row-cols-lg-3">
                <div className="feature col">
                  <a href="/facturacion">
                  <div className="feature-icon d-inline-flex align-items-center justify-content-center bg-gradient mb-3">
                    <i className="bi bi-receipt"></i>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">Facturación</h3>
                  </a>
                  <p>
                    Gestiona todas tus ventas con facturación eficiente y
                    personalizada, adaptada a las necesidades de tu negocio.
                    fácil de usar y gestionar
                  </p>
                </div>
                
                <div className="feature col">
                  <div className="feature-icon d-inline-flex align-items-center justify-content-center bg-gradient mb-3">
                    <i className="bi bi-person-bounding-box"></i>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">Clientes</h3>
                  <p>
                    Organiza y consulta la información de tus clientes para
                    mejorar la atención y fidelización. historial de pagos,
                    limite de crédito, cliente leal, recordatorios.
                  </p>
                </div>
                <div className="feature col">
                  <div className="feature-icon d-inline-flex align-items-center justify-content-center bg-gradient mb-3">
                    <i className="bi bi-file-earmark-spreadsheet"></i>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">Productos</h3>
                  <p>
                    Administra tu catálogo de productos y servicios, incluyendo
                    precios, imágenes, lista de precios, descuentos, impuestos,
                    descripciones y categorías.
                  </p>
                </div>
              </div>
              <div className="row g-3 py-6 row-cols-1 row-cols-lg-3">
                <div className="feature col">
                <a href="/inventario">
                  <div className="feature-icon d-inline-flex align-items-center justify-content-center bg-gradient mb-3">
                    <i className="bi bi-database-check"></i>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">Inventario</h3>
                  </a>
                  <p>
                    Controla tus existencias en tiempo real, evitando rupturas
                    de stock y optimizando las compras y visualiza historia de transacciones.
                  </p>
                </div>
                <div className="feature col">
                  <div className="feature-icon d-inline-flex align-items-center justify-content-center bg-gradient mb-3">
                    <i className="bi bi-file-medical"></i>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">NCF</h3>
                  <p>
                    Genera y gestiona comprobantes fiscales según las normativas
                    de la DGII configura notificaciones cuando se estén agotando.
                  </p>
                </div>
                <div className="feature col">
                  <div className="feature-icon d-inline-flex align-items-center justify-content-center bg-gradient mb-3">
                    <i className="bi bi-person-rolodex"></i>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">Proveedores</h3>
                  <p>
                    Mantén al día la información de tus proveedores, facilitando
                    el control de compras y pagos.
                  </p>
                </div>
              </div>
              <div className="row g-3 py-6 row-cols-1 row-cols-lg-3">
                <div className="feature col">
                  <div className="feature-icon d-inline-flex align-items-center justify-content-center bg-gradient mb-3">
                    <i className="bi bi-person-square"></i>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">Empleados</h3>
                  <p>
                    Registra y gestiona la información laboral de tus empleados,
                    incluyendo permisos y horarios.
                  </p>
                </div>
                <div className="feature col">
                  <div className="feature-icon d-inline-flex align-items-center justify-content-center bg-gradient mb-3">
                    <i className="bi bi-cash-coin"></i>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">Comisión ventas</h3>
                  <p>
                    Calcula y administra comisiones de ventas para tu equipo,
                    incentivando el rendimiento.
                  </p>
                </div>
                <div className="feature col">
                <a href="/reportes">
                  <div className="feature-icon d-inline-flex align-items-center justify-content-center bg-gradient mb-3">
                    <i className="bi bi-file-bar-graph"></i>
                  </div>
                  <h3 className="fs-2 text-body-emphasis">Reportes </h3>
                  </a>
                  <p>
                    Obtén informes detallados y personalizados sobre el
                    rendimiento y la gestión de tu negocio.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </header>
      </article>
    </>
  );
};
export default Features;
