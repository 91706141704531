import React, { useState } from "react";
import SEO from "../components/seo";

const Contact = (props) => {
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  const send = () => {
    if (inputs.name) return;
    if (inputs.email) return;
    if (inputs.message) return;
    
    setIsOpen(true);
  };
  return (
    <>
    <SEO
        title="ProVenta - ayuda y soporte técnico"
        description="este es tu espacio en donde podrás exponer tus dudas o reportar problemas técnicos"
        name="ProVenta"
        type="article"
      />
      <div className="page-header theme-bg-dark py-5 text-center position-relative">
        <div className="theme-bg-shapes-right"></div>
        <div className="theme-bg-shapes-left"></div>
        <div className="container">
          <h1 className="page-heading single-col-max mx-auto">
            Contacto y soporte
          </h1>
          <div className="page-intro single-col-max mx-auto">
            Si tienes alguna pregunta o necesitas soporte especializado, no
            dudes en ponerte en contacto con nosotros. Nuestro equipo está aquí
            para ayudarte en lo que necesites.
          </div>
        </div>
      </div>
      <div className="page-content py-5">
        <section className="options-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 mb-5">
                <div className="media shadow-sm p-4 rounded h-100">
                  <img
                    className="mr-3"
                    src="/assets/images/streamline-free/customer-service-man.svg"
                    alt="soporte técnico"
                    width={193}
                  />
                  <div className="media-body">
                    <h5 className="mt-0">
                      <a href="/">
                        Soporte técnico
                        <svg
                          className="svg-inline--fa fa-long-arrow-alt-right fa-w-14 ml-2"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="long-arrow-alt-right"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"
                          ></path>
                        </svg>
                      </a>
                    </h5>
                    Si tienes problemas, Nuestro equipo está aquí para ayudarte
                    nos puedes escribir a{" "}
                    <a href="mailto:ayuda.proventa@gmail.com">
                      ayuda.proventa@gmail.com{" "}
                    </a>{" "}
                    o al numero de whatsapp{" "}
                    <i className="fa-brands fa-whatsapp"></i> (809) 787-4963
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mb-5">
                <div className="media shadow-sm p-4 rounded h-100">
                  <img
                    className="mr-3"
                    src="/assets/images/streamline-free/write-paper-ink.svg"
                    alt="ayuda"
                    width={193}
                  />
                  <div className="media-body">
                    <h5 className="mt-0">
                      <a href="/introduccion">
                        Documentación
                        <svg
                          className="svg-inline--fa fa-long-arrow-alt-right fa-w-14 ml-2"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="long-arrow-alt-right"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"
                          ></path>
                        </svg>
                      </a>
                    </h5>
                    Si tienes problemas, consulta nuestra documentación en línea
                    o solicita soporte técnico. Nuestro equipo está disponible
                    para asistirte con cualquier duda
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-form-section mb-5">
          <div className="container">
            <form
              id="contact-form"
              className="contact-form p-5 col-lg-9 mx-lg-auto theme-bg-light shadow"
              noValidate="novalidate"
              
            >
              <h3 className="text-center">Envíanos tus preguntas</h3>
              <div className="mb-3 text-center">
                Es importante que completes todos los datos a continuación
              </div>
              <div className="form-row">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="sr-only" htmlFor="cname">
                      Tu nombre
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cname"
                      name="name"
                      placeholder="Nombre"
                      minLength="2"
                      required=""
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="sr-only" htmlFor="cemail">
                      Tu Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="cemail"
                      name="email"
                      placeholder="Email"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group col-12  mt-4">
                  <label className="sr-only" htmlFor="cmessage">
                    Mensaje
                  </label>
                  <textarea
                    className="form-control"
                    id="cmessage"
                    name="message"
                    placeholder="Escribe tu mensaje aquí"
                    rows="10"
                    required=""
                  ></textarea>
                </div>
                <div className="form-group col-12 mt-4">
                  <button type="button" onClick={() => send()} className="btn btn-block btn-primary py-2">
                    Enviar
                  </button>
                </div>
              </div>
              {isOpen && (
              <div className="alert alert-success mt-4" role="alert">
                Mensaje enviado, pronto nos pondremos en contacto.
              </div>
            )}
            </form>
          </div>
        </section>
      </div>
    </>
  );
};
export default Contact;
